<template>
  <div class="content-wrapper projects-manage">
    <a-form
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
      :form="form"
      @submit="handleSubmit"
    >
      <a-row :gutter="24">
        <a-col :span="8">
          <a-form-item label="项目名称">
            <a-input
              allowClear
              placeholder="请输入项目名称"
              v-decorator="['name']"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" style="text-align: right">
          <a-button type="primary" html-type="submit" @click="handleSubmit"
            >搜索</a-button
          >
          <a-button style="margin: 0 8px" @click="handleReset">重置</a-button>
        </a-col>
      </a-row>
    </a-form>

    <a-table rowKey="id" :columns="columns" :data-source="list">
      <a slot="name" slot-scope="text">{{ text }}</a>
      <span slot="create_time" slot-scope="text">{{
        text | moment("YYYY-MM-DD HH:mm:ss")
      }}</span>
    </a-table>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      form: this.$form.createForm(this, { name: "users-manage" }),
      list: [],
      columns: [
        {
          title: "项目名",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
          width: 120,
        },
        {
          title: "描述",
          dataIndex: "description",
          key: "description",
          width: 150,
          ellipsis: true,
        },
        {
          title: "接口数量",
          dataIndex: "api_count",
          key: "api_count",
          width: 50,
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
          scopedSlots: { customRender: "create_time" },
          width: 120,
        },
      ],
    };
  },
  mounted() {
    this.getProjectsList();
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.getProjectsList(values);
        }
      });
    },
    handleReset() {
      this.form.resetFields();
    },
    async getProjectsList(values) {
      this.list = await this.$store.dispatch("queryProjects", values);
    },
  },
};
</script>

<style lang="scss">
.projects-manage {
  .ant-form {
    width: 100%;
  }
}
</style>
